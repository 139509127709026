export default { 
  h42c6e5bf5f8cf2f75ea98b07b302d87e: [[({}, []) => ["Login to see Terms & Conditions and Privacy Policy."], {}], [({}, []) => ["Login to see Deposit Account Agreement and Privacy Policy."], {
  "programType": ["consumer_dda"]
}]],
  h436ed5ad29cad65237602494c295eb00: [[({}, []) => ["All other trademarks and service marks belong to their respective owners."], {}]],
  h957a81051c341202889e46472c6e00f1: [[({}, [C0]) => ["Mastercard and the circles design are trademark of Mastercard International Incorporated.", C0()], {}]],
  h990fd375dcf8200e9aba15c55d64ba6e: [[({
  mfeConfig: mfeConfig
}, [C0]) => ["Version ", C0(mfeConfig.version)], {}]],
  h9f41fb59a473f76f65ebbd8570f68155: [[({}, [C0, C1]) => ["Apple", C0("\xAE"), " and the Apple logo", C1("\xAE"), " are trademarks of Apple Inc., registered in the U.S. and other countries. Apple Inc."], {}]],
  hc28d8f302084010c06a994382269c7a4: [[({}, []) => ["Card may be used everywhere Debit Mastercard is accepted."], {}]],
  hd3e6fe9ff04dc2f30034ad4271fbbaf4: [[({}, [C0, C1]) => ["Apple", C0("\xAE"), " and the Apple logo", C1("\xAE"), " are trademarks of Apple Inc., registered in the U.S. and other countries. App Store is a service mark of Apple Inc."], {}]],
  hd76d2b8b5bc309babfefb9dee83a1505: [[({}, []) => ["Login to see Terms & Conditions or Deposit Account Agreement and Privacy Policy."], {}]],
  hf3bcb0b9dc3780be0b5d46caae7f35e8: [[({}, []) => ["Google Play and the Google Play logo are trademarks of Google LLC."], {}]] 
}