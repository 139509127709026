import __NS_DYNAMIC_REACT_CONTENT___0 from "./unauthed-footer.strings/content.default.js";
import { Content, createScopedReactContentImporter } from "packages/react-content/runtime";
const NS_DYNAMIC_REACT_CONTENT_MANAGER = createScopedReactContentImporter(undefined, {
  "default": () => __NS_DYNAMIC_REACT_CONTENT___0
});
import { createElement, Fragment } from 'react';
import { Bold, Disclosure } from 'legos/typography';
import { IssuerStatement } from 'apps/acp/packages/issuer-statement/react';
import { CopyrightStatement } from 'apps/acp/packages/content-copyright-statement';
import { isAndroid, isIOS, isWeb } from 'packages/platform-detector';
import { createUnAuthedGetBrandingRequest } from 'apps/acp/packages/webapi';
import { useHttpQuery } from 'packages/http-client/react';
import { showMobileAppsSection } from 'apps/acp/packages/mobile-apps-section-detector';
import { limitedUseAccessToken } from 'apps/acp/packages/limited-use-access-token';
import { AdaChatButton } from 'apps/acp/packages/ada-chatbot';
const isCordova = isAndroid() || isIOS();
export const UnauthedFooter = ({
  mfeConfig
}) => {
  const {
    brand
  } = useHttpQuery(limitedUseAccessToken(createUnAuthedGetBrandingRequest()));
  return createElement(Fragment, null, mfeConfig.showLoginTermsAndConditionsDisclosure && createElement(Disclosure, null, mfeConfig.showChangedTermsDepositPrivacyDisclosure && createElement(Content, {
    hash: "d76d2b8b5bc309babfefb9dee83a1505",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  }), !mfeConfig.showChangedTermsDepositPrivacyDisclosure && createElement(Content, {
    hash: "42c6e5bf5f8cf2f75ea98b07b302d87e",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), mfeConfig.importantPatriotActDisclosure && createElement(Disclosure, {
    dangerouslySetInnerHTML: {
      __html: mfeConfig.importantPatriotActDisclosure
    }
  }), createElement(Disclosure, null, createElement(IssuerStatement, null)), mfeConfig.showMastercardDisclosures && createElement(Disclosure, null, createElement(Content, {
    hash: "957a81051c341202889e46472c6e00f1",
    devVariables: {},
    componentsAndExpressions: [() => !mfeConfig.showCardUseDisclosure && createElement(Fragment, {
      key: "showCardUseDisclosure"
    }, createElement("br", null), createElement("br", null), createElement(Content, {
      hash: "c28d8f302084010c06a994382269c7a4",
      devVariables: {},
      componentsAndExpressions: [],
      importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
    }))],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), mfeConfig.showShortAppleDisclosure && createElement(Disclosure, null, createElement(Content, {
    hash: "9f41fb59a473f76f65ebbd8570f68155",
    devVariables: {},
    componentsAndExpressions: [(...children) => createElement("sup", null, children), (...children) => createElement("sup", null, children)],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), !mfeConfig.showShortAppleDisclosure && !showMobileAppsSection(mfeConfig.programType || '', brand.ios_app_url) && createElement(Disclosure, null, createElement(Content, {
    hash: "d3e6fe9ff04dc2f30034ad4271fbbaf4",
    devVariables: {},
    componentsAndExpressions: [(...children) => createElement("sup", null, children), (...children) => createElement("sup", null, children)],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), !showMobileAppsSection(mfeConfig.programType || '', brand.android_app_url) && createElement(Disclosure, null, createElement(Content, {
    hash: "f3bcb0b9dc3780be0b5d46caae7f35e8",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), (!showMobileAppsSection(mfeConfig.programType || '', brand.android_app_url) || !showMobileAppsSection(mfeConfig.programType || '', brand.ios_app_url)) && createElement(Disclosure, null, createElement(Content, {
    hash: "436ed5ad29cad65237602494c295eb00",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(Disclosure, null, createElement(CopyrightStatement, {
    statement: mfeConfig.copyrightStatement
  })), isCordova && createElement(Disclosure, {
    onClick: () => {
      var _a, _b;

      return ((_a = mfeConfig) === null || _a === void 0 ? void 0 : _a.mobileAppVersionOnClick) && ((_b = mfeConfig) === null || _b === void 0 ? void 0 : _b.mobileAppVersionOnClick(true));
    },
    style: {
      textAlign: 'center'
    }
  }, createElement(Content, {
    hash: "990fd375dcf8200e9aba15c55d64ba6e",
    devVariables: {
      mfeConfig: mfeConfig
    },
    componentsAndExpressions: [(...children) => createElement(Bold, null, children)],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), isWeb() && createElement(AdaChatButton, null));
};